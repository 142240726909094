import { Component } from '@angular/core';

import { SpinnerComponent } from '@site-mate/global-web-ui';

@Component({
  selector: 'fs-load-page',
  template: `
    <div class="flex h-screen items-center justify-items-center">
      <div class="m-auto">
        <gw-spinner />
      </div>
    </div>
  `,
  standalone: true,
  imports: [SpinnerComponent],
})
export class LoadPageComponent {}
